
  .footer-container {
  
    background-color:#93D053;
    width: 100%;
    height: auto; 
    margin: auto;
    display: flex;
    flex-direction: column;
    color: rgb(25, 80, 25);
    
  }
  
  .logo2 {
    
    border-top:3px solid rgb(23, 59, 6);
    color:rgb(23, 59, 6);
    font-weight: 600;
    font-size: 18px;
    margin-left: 0;
    font-family: 'Poppins', sans-serif;
  
  }
  
  a:link {
    text-decoration: none;
  }
  a:visited{
    text-decoration: none;
    color:rgb(17, 54, 12);
  }
  @media(min-width: 768px) {
    .logo2 {
   
      font-weight: 800;
      font-size: 20px;
      
      margin-left: 0;
      font-family: 'Poppins', sans-serif;
    
    }
  } 
 .phone {
    font-size: 16px;
    color: rgb(25, 80, 25);
 }
  .footer-links {
    display: flex;
    justify-content: center
  } 
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3rem;
    text-align: left;
    width: 260px;
    height:auto;
    box-sizing: border-box;
  }
  
   .rounded{
    width: 100%;
    background-color:rgb(17, 54, 12)!important;
  } 
   .rounded:hover {
    background-color:rgb(42, 109, 33)!important;
   }
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: rgb(25, 80, 25);
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #fbf9f9;
  }
  
  
  .social-media {
    max-width: 1000px;
    width: 100%;
    margin: auto;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: auto;
  }

  
   .website-rights {
   
    color: rgb(25, 80, 25);
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    font-weight: inherit;
    margin: auto;
    margin-bottom: 8px;
    
    align-items: center;
    display: flex;
    justify-content: center;
   
  } 
  .nextek {
 
    color: rgb(25, 80, 25);
    text-decoration: none;
  }
 
   @media screen and (max-width: 650px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 10rem;
    }
  
    .rounded {
      width: 100%;
      background-color: #93D053;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
      margin: auto;
    }
  }  
  
  
  
  
  
  @media (min-width:550px) {
    .form-control {
      margin-bottom: 1rem;
    }
  }
  .form-control {
    margin-bottom: 1rem;
   
  }
  
  @media (max-width: 549px) {
    .form-control {
      display: flex;
      margin-bottom: 1rem;
      height: auto;
    }
     .footer-container {
     padding-left: 1px;
    } 
  }
  .text-left {
   width: 50%;
   height: 20%; 
  }
  
  .phone {
    
    padding-bottom: 3rem;
  }
  
  