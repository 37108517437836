.massage-home {
    background-color: none;
    width: 90%;
    height:auto;
    margin: auto; 
    margin-bottom: 2rem;

}


.pics1 { 
  
    background-image: url(../images/vic.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    width:auto;
    height:400px;  
  
}
.pics2 {
   
    background-image: url(../images/vic1.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    width:auto; 
    height:400px;
  
}
.grid-container { 
  
    margin: auto;
    width:100%;
    height: auto;
    display: grid;
    grid-template-columns:  4 / 1;
    padding: 10px;
    gap: 50px 20px;
   
  }
  
  /* .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  } */

.content-pics {
    border-bottom:3px solid grey;
   
    line-height: 1.6em;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: inherit;
    color: #252525;
}
.middle {
    padding-top: 2rem;
}

@media (min-width:768px) {
    .grid-container {
        margin: auto;
        width:90%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        
      }  
      .content-pics {
       vertical-align: middle;
      }
     
}