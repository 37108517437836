 .navbar-container {
  position: sticky; 
  top: 0;
  z-index: 2;
  /* overflow: hidden; */
   padding-top: 1rem; 
 
 }

.navbar {
  background-color:transparent;
  width: 100%;
  height: 80px;
  display: flex;
  position: -webkit-sticky;
   margin-top: -2rem;
   font-family: 'Poppins', sans-serif;
   font-weight: inherit;
 
}
.navbar.active {
  color: #fff;
  background-color: rgb(147, 208, 83, 0.3);
  height: auto;
  padding-top: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: inherit;
}


.nav-items {
  width: 65vw;
  display: flex;
  
  /* justify-content: flex-center;  */
  /* margin-right: 2rem; */
}

.nav-item {

  list-style: none;
  margin-left: 2rem;
}

.nav-item a {
  text-decoration: none;
  color: rgb(53, 83, 19);
  font-size: 1.2rem;
  padding: 6px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s all ease-out;
}

.nav-item a:hover {
  background: rgba(247, 239, 244, 0.5);
  color: rgb(93, 193, 95);
}

.nav-item a span {
  margin-left: 0.6rem;
  font-size: 1.3rem;
}

.sidebar-toggle {
 
  color:rgb(14, 75, 17);
  width: 45vw;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}



.sidebar {
  width: 16rem;
  background: rgb(147, 208, 83, 0.8);;
  height: 55vh;
  position: absolute;
  left: -100%;
  top:0;
  transition: 0.3s all ease-in-out;
}

.sidebar.active {
  left: 0;
}

.sidebar-items {
  font-size: 1.2rem;
  display: flex;
 align-items: start;
  flex-direction: column;
  transition: 0.2s all ease-out;
}

.sidebar-item {
  list-style: none;
  margin-top: 1rem;
  margin-left: 1rem;
  text-align: center;
  width: 80%;
  height: 5rem;
  padding-right:2rem;
  border-bottom: 2px solid rgb(14, 75, 17, 0.3);
}

.sidebar-item a {
  text-decoration: none;
  color: rgb(14, 75, 17);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-out;
}

.sidebar-item a span {
  margin-left: 1.5rem;
  font-size: 1.4rem;
}

.sidebar-item a:hover {
  background: rgb(93, 193, 95);
  color:rgb(227, 230, 230);
}

.sidebar .btn {
  margin-top: 2.4rem;
  width: 100%;
}

@media (min-width:768px) {
 
  .sidebar-toggle-logo {
    font-size: 1rem;
    right: 8%;
    top: 25px;
  }

}
@media screen and (max-width: 900px) {
  .sidebar.active {
    width: 70%;
  }

  .sidebar-item a span {
    display: block;
  } 
}
@media (min-width:1024px) {
  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 6rem;
    height: 4rem;
  }
  
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 1rem;
  }
}
@media (min-width:1440px) {

  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 8rem;
    font-size: 2rem;
  }
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 1.5rem;
  }
}

 @media (min-width:2560px) {

  .nav-items {
    margin-right: 10%;
  }
  .nav-item a {
    width: 12rem;
    font-size: 2rem;
  }
  .nav-item a span {
    margin-left: 0.6rem;
    font-size: 2.2rem;
  }
} 

.logo {
  width:20%;
  border-top:3px solid rgb(23, 59, 6);
  /* background-color: rgb(93, 193, 95, 0.5); */
 
  color: rgb(23, 59, 6);
  font-weight: 300;
  font-size: 12px;
  margin-left: 1rem;
  font-family: 'Pathway Gothic One', sans-serif;

}

a:link {
  text-decoration: none;
  
}
@media(min-width: 768px) {
  .logo {
    font-weight: 800;
    font-size: 20px;
    margin-left: 1rem;
    font-family: 'Poppins', sans-serif;
  
  }
}