 .cont-services{
   width: 100%;
   height: auto;
   margin: auto;
   margin-top: 2rem;
    background: none;
    line-height: 1.6em;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: inherit;
    color: #252525;
  }
  
  .container{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
  }
   
  .grid-container {
  
    margin: auto;
    width:100%;
    height: auto;
    display: grid;
    grid-template-columns:  4 / 1;
    padding: 10px;
    gap: 50px 20px;
   
  }
  @media (min-width:768px) {
    .grid-container {
        margin: auto;
        width:90%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        
      }
     }
  .cart{
   
    padding: 1rem;
    background: transparent;
    border-radius: 3px;
    box-sizing: border-box;
    display: block;
    line-height: 1.6em;
    margin-bottom: 5px;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: inherit; 
    color: #252525; 
  }

  h4{
    padding-top: 1rem;
  }
  .img-all {
  
    width: 80%;
    height: 200px;
    
    margin:  auto;
    background-color: none;
    background-position: center;
    background-size: cover;
  }
  .cart .image-one{
    background-image: url(../images/home.jpg);    
  } 

  .cart .image-two{
    background-image: url(../images/firm.jpg);  
  }
  .cart .image-three{
    background-image: url(../images/window.jpg); 
  }
  .cart .image-four{
    background-image: url(../images/move.jpg); 
   }
  .cart .image-five{
    background-image: url(../images/stairs.jpg); 
   }
  .cart .image-six{
    background-image: url(../images/renov.jpg); 
    }

  
  .cart .title-services{
    font-size: 16px;
    color: #252525;
    margin: auto;
  }
  
  
 @media (max-width:320px){
  .cart .title-services{
    font-size:16px;
  }
 }

  @media only screen and (min-width: 600px) {
    .cart-container{
      width: 50%;
    }
  }
  
  @media only screen and (min-width: 1200px) {
    .cart-container{
      width: 25%;
    }
  }
  
  /* .carts-circle{
    max-width: 1200px;
    margin: 0 auto;
  }
    */
 
   