
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

 
}

#root {
  background-color: #FFFFFF;
  color: #252525;
}

.about,
.contact,
.blog,
.shop,
.gallery,
.services {
  justify-content: center;
  align-items: center;
  /* font-size: 4rem; */
  background-position: center;
  background-repeat: none;
  background-size: cover;
  
}
.title {
  color:rgb(20, 50, 54);
  font-size: 2vw;

  line-height: 1.6em;
  margin-bottom: 5px;

  font-family: 'Poppins', sans-serif;
  font-weight: inherit;
  color: #252525;
  text-transform: uppercase;
  padding-bottom: 2rem;
}
@media(max-width:768px) {
 .title {
  font-size: 18px;  
 }
}



h1 {
  align-items: center;
  display: flex;
  justify-content: center;
  
}
