.hero-container {
    position: relative;
    width: 100%;
    height: auto ; 
    min-height: 200px;
    margin: auto;
    margin-bottom: 1rem; 
    margin-top: -5rem;
    position:relative;
    display: flex;
    z-index: 1;
} 
.clean {
  width:100%;
  height: auto;
  background-image: url(../images/clean5.jpg);
  background-position: center;
  background-size:cover;
  background-repeat: no-repeat; 
}



/* .link_hero {
  box-shadow: 2px 2px 5px rgb(11, 116, 116);
  position: absolute;
  width: 20%;
  height: 10%;
  bottom:15%;
  left:15%;
  text-align: center;
  text-decoration: none;
  background-color: rgb(10, 151, 151);
  color: aliceblue;
  border-radius:  20px;
  font-size: 1vw;
} */
.align-middle {
 
  margin-top:4%;
}


/* .link_hero:hover {
  background-color: rgb(15, 165, 165);
  color: rgb(158, 238, 234);
}
.link_hero:active {
  background-color: rgb(9, 104, 104);
  color: rgb(219, 235, 245);
} */

 
 .allTitle {
  position: absolute;
  top:30%;
  left: 10%;
  text-transform: uppercase;
 }
 .hero-title1 { 
  text-transform: none;
  font-size:3vw;
  text-align:left;
  line-height: 1.6em;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: rgb(25, 80, 25);
} 
   .hero-title2 {
    text-transform: none;
  /* font-size:8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600; */
  color: rgb(102, 122, 102);
 
}  
.titlespan {
  font-size:16px;
  color: rgb(40, 116, 41);
  font-weight: 300;
}
  
 @media (min-width:595px) {
  .allTitle {
    top:30%;
    left: 5%;
   }
   .hero-title1 { 
    font-size:3vw;
  } 
    .hero-title2 {
    font-size:1.5vw;
    
  } 
  
} 
 @media(max-width:594px) {
   
   .allTitle {
    top:35%;
    left: 5%;
   }
   .hero-title1 { 
    text-transform: none;
    font-size:16px;
    font-weight: 600;
  } 
    .hero-title2 {
      text-transform: none;
    font-size:14px;
  } 
  .link_hero  {
    left: 20%;
    font-size: 2.5vw;
  }
  
 }
 
 @media(min-width:600px) {

  .clean {
    width:100%;
    height: 40vh;
  }
   
  .allTitle {
    top:35%;
    left: 5%;
    
   }
   .hero-title1 { 
    font-size:3vw;
    font-weight: 600;
  } 
    .hero-title2 {
    font-size:2vw;
  } 
 }
 @media(min-width:1200px) {

  .clean {
    width:100%;
    height: 50vh;
  }
   
  .allTitle {
    top:35%;
    left: 5%;
    
   }
   .hero-title1 { 
    font-size:3vw;
    font-weight: 600;
    letter-spacing: 3px;
  } 
    .hero-title2 {
    font-size:1vw;
    letter-spacing: 2px;
  } 
 }